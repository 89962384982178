<template>
  <DxDataGrid
    id="inventory-data-grid"
    ref="gridView"
    class="sxr-grid regular-text-color"
    :class="{ 'filter-panel-expanded': expandFilters }"
    width="100%"
    height="100%"
    :data-source="datasource"
    :show-borders="false"
    :show-row-lines="true"
    :show-column-lines="true"
    :remote-operations="true"
    :word-wrap-enabled="true"
    :no-data-text="noResultsFoundMessage"
    :column-auto-width="true"
    :allow-column-reordering="true"
    :allow-column-resizing="!isCurrentBreakPointXs"
    column-resizing-mode="widget"
    :on-row-prepared="onRowPrepared"
    @exporting="onExporting"
    @exported="onExported"
  >
    <DxToolbar>
      <DxItem
        location="before"
        template="filterPanelTemplate"
      />
      <DxItem
        name="columnChooserButton"
      />
      <DxItem
        name="exportButton"
        locate-in-menu="always"
      />
    </DxToolbar>
    <DxHeaderFilter
      :visible="false"
      :hide-select-all-on-search="false"
    />
    <DxPaging :page-size="25" />
    <DxScrolling
      mode="virtual"
    />
    <DxExport
      :enabled="true"
      :formats="['csv', 'xlsx']"
      :texts="{
        exportAll: 'Export to {0}'
      }"
    />
    <DxStateStoring
      :enabled="false"
      type="localStorage"
      storage-key="inventory-data-grid-state"
    />
    <DxColumnChooser
      :enabled="true"
      mode="select"
    />
    <DxColumn
      data-field="image"
      caption="Image"
      data-type="string"
      :min-width="100"
      :allow-resizing="false"
      :allow-sorting="false"
      :fixed="true"
      css-class="fixed-column-cell"
      fixed-position="left"
      cell-template="imageCellTemplate"
    />
    <DxColumn
      data-field="asin"
      caption="ASIN"
      data-type="string"
      :min-width="160"
      :fixed="true"
      css-class="fixed-column-cell"
      fixed-position="left"
      cell-template="asinCellTemplate"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="marketplace_id"
      caption="Marketplace"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="available"
      caption="Available"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="fc_transfer"
      caption="FC Transfer"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="fc_processing"
      caption="FC Processing"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="customer_order"
      caption="Customer Order"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="working"
      caption="Working"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      :show-in-column-chooser="false"
      :allow-editing="false"
      :allow-exporting="false"
      :allow-sorting="false"
      data-field="marketplace"
      caption=""
      data-type="string"
      :fixed="true"
      :allow-resizing="false"
      css-class="fixed-column-cell"
      fixed-position="left"
      :min-width="50"
      alignment="center"
      cell-template="marketplaceCell"
    />
    <DxColumn
      data-field="sku"
      caption="SKU"
      data-type="string"
      :min-width="180"
      :visible="false"
    />
    <DxColumn
      data-field="sellable"
      caption="Sellable"
      sort-order="desc"
      data-type="string"
      :min-width="160"
      cell-template="sellableCellTemplate"
    />
    <DxColumn
      data-field="inbound"
      caption="Inbound"
      data-type="string"
      :min-width="160"
      cell-template="inboundCellTemplate"
    />
    <DxColumn
      data-field="unsellable"
      caption="Unsellable"
      data-type="string"
      :min-width="160"
      cell-template="unsellableCellTemplate"
    />
    <DxColumn
      data-field="stock"
      caption="Stock"
      data-type="string"
      :min-width="120"
      cell-template="stockCellTemplate"
    />
    <DxColumn
      data-field="status"
      caption="Status"
      data-type="string"
      alignment="center"
      :min-width="120"
    />
    <DxColumn
      data-field="shipped"
      caption="Sales Velocity"
      data-type="string"
      :min-width="160"
      cell-template="shippedCellTemplate"
    />
    <DxColumn
      data-field="supply"
      caption="FBA Inventory"
      data-type="string"
      :min-width="180"
      cell-template="supplyCellTemplate"
      header-cell-template="captionWithQuestionMarkTemplate"
    />
    <template #captionWithQuestionMarkTemplate="{ data }">
      <div class="d-flex flex-row">
        <div>{{ data.column.caption }}</div>
        <div
          v-if="getCaptionHint(data.column)"
          class="ml-50"
          @click.stop="false"
        >
          <feather-icon
            v-b-tooltip.hover="getCaptionHint(data.column)"
            icon="HelpCircleIcon"
          />
        </div>
      </div>
    </template>
    <template #asinCellTemplate="{ data }">
      <AsinElement
        :asin="data.data.asin"
        :amazon-url="data.data.amazon_url"
      />
    </template>
    <template #filterPanelTemplate>
      <Filters
        :expand-filters="expandFilters"
        @on-update="onFilterUpdate"
        @on-show-filter-click="onShowFilterClick"
      />
    </template>
    <template #sellableCellTemplate="{ data }">
      <div
        v-b-tooltip.hover="getSellableTooltipContent(data.key)"
        class="cursor-pointer text-center"
      >{{ formatThousands(data.key.sellable.total) }}</div>
    </template>
    <template #inboundCellTemplate="{ data }">
      <div class="text-center">
        {{ formatThousands(data.key.inbound) }}
      </div>
    </template>
    <template #unsellableCellTemplate="{ data }">
      <div
        v-b-tooltip.hover="getUnsellableTooltipContent(data.key)"
        class="cursor-pointer text-center"
      >{{ formatThousands(data.key.unsellable.total) }}</div>
    </template>
    <template #shippedCellTemplate="{ data }">
      <div class="f-flex flex-column text-center">
        <div class="small">
          Selling
        </div>
        <div>{{ data.key.shipped.display }}</div>
        <div class="small">
          a day
        </div>
      </div>
    </template>
    <template #supplyCellTemplate="{ data }">
      <div class="f-flex flex-column text-center">
        <div class="small">
          Good for
        </div>
        <div>{{ formatAmountOfMonth(data.key.supply) }}</div>
        <div class="small">
          {{ getSupplyMeasure(data.key.supply.amount) }}
        </div>
      </div>
    </template>
    <template #imageCellTemplate="{ data }">
      <div class="f-flex flex-column text-center">
        <ProductImage
          :size="!isCurrentBreakPointXs ? '59px' : '42px'"
          :src="data.key.image"
          :title="data.key.title"
          icon-size="32"
          :is-zoom-available="true"
          :marketplace="data.key.marketplace.marketplace_id"
          :asin="data.key.asin"
          :product-url="data.key.amazon_url"
          :attr-class="!isCurrentBreakPointXs ? 'tw-p-4' : 'tw-p-1.5'"
        />
      </div>
    </template>
    <template #marketplaceCell="{ data }">
      <CountryFlag
        :key="data.data.marketplace.country_code"
        :country="data.data.marketplace.country_code"
        :title="data.data.marketplace.country"
        :alt="data.data.marketplace.country"
        :size="isCurrentBreakPointXs ? 'small' : undefined"
      />
    </template>
    <template #stockCellTemplate="{ data }">
      <div
        class="text-center"
        :class="{
          'text-danger': ['Out Of Stock', 'Too Low'].includes(data.key.stock),
          'text-warning': data.key.stock === 'Low',
          'text-success': ['Ideal', 'High'].includes(data.key.stock),
        }"
      >
        {{ data.key.stock }}
      </div>
    </template>
  </DxDataGrid>
</template>

<script>
import {
  DxColumn,
  DxColumnChooser,
  DxDataGrid, DxExport,
  DxHeaderFilter,
  DxItem,
  DxPaging, DxScrolling, DxStateStoring,
  DxToolbar,
} from 'devextreme-vue/data-grid'
import CountryFlag from 'vue-country-flag'
import { formatNumber } from 'devextreme/localization'
import { VBTooltip } from 'bootstrap-vue'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import Filters from '@/views/inventory-v2/components/Filters.vue'
import InventoryDataSource from '@/data/inventory.datasource'
import ProductImage from '@/components/ProductImage.vue'
import AsinElement from '@/components/AsinElement.vue'

export default {
  name: 'DataGrid',
  components: {
    DxExport,
    ProductImage,
    Filters,
    DxDataGrid,
    DxColumn,
    DxColumnChooser,
    DxToolbar,
    DxItem,
    DxPaging,
    DxHeaderFilter,
    CountryFlag,
    DxScrolling,
    DxStateStoring,
    AsinElement,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      noResultsFoundMessage: 'No Result Found',
      datasource: InventoryDataSource.getInstance(this.$store),
      expandFilters: false,
      captionHints: {
        supply: 'Includes sellable and inbound inventory in calculation.',
      },
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    gridInstance() {
      return this.$refs.gridView.instance
    },
  },
  methods: {
    isEmpty(value) {
      return value === undefined || value === null || value.length === 0
    },
    onFilterUpdate({
      search, marketplaces, stock, status,
    }) {
      const filters = []
      if (search && search.length) {
        if (filters.length) {
          filters.push('and')
        }
        filters.push(['search', '=', search])
      }
      if (marketplaces && marketplaces.length) {
        if (filters.length) {
          filters.push('and')
        }
        filters.push(['marketplaces', '=', marketplaces])
      }

      if (stock && stock.length) {
        if (filters.length) {
          filters.push('and')
        }
        filters.push(['stock', '=', stock])
      }

      if (status && status.length) {
        if (filters.length) {
          filters.push('and')
        }
        filters.push(['status', '=', status])
      }

      if (filters.length) {
        this.gridInstance.filter(filters)
      } else {
        this.gridInstance.clearFilter()
      }
    },
    formatThousands({ amount, display }) {
      if (amount >= 1000) {
        return formatNumber(amount, 'thousands')
      }

      return display
    },
    formatAmountOfMonth({ amount, display }) {
      if (amount > 60) {
        return Math.round(amount / 30)
      }

      return display
    },
    getSupplyMeasure(value) {
      if (value > 60) {
        return 'months'
      }

      return 'days'
    },
    getUnsellableTooltipContent(item) {
      return {
        title: `Customer Order: ${item.unsellable.customer_order.display} <br />
        Working: ${item.unsellable.working.display}`,
        html: true,
      }
    },
    getSellableTooltipContent(item) {
      return {
        title: `Available: ${item.sellable.available.display} <br />
        FC Transfer: ${item.sellable.fc_transfer.display} <br />
        FC Processing: ${item.sellable.fc_processing.display} <br />
        My WH: ${item.sellable.my_warehouse.display}`,
        html: true,
      }
    },
    onRowPrepared(info) {
      if (info.rowType === 'data') {
        const { data: item, rowElement } = info
        let cssClass = ''
        if (item.sellable.total.amount === 0 || (item.shipped.amount > 0 && item.supply.amount <= 30)) {
          cssClass = 'text-danger'
        } else if (item.sellable.total.amount > 0 && item.shipped.amount > 0 && item.supply.amount > 30 && item.supply.amount <= 60) {
          cssClass = 'text-warning'
        } else if (item.sellable.total.amount > 0 && ((item.shipped.amount > 0 && item.supply.amount > 60) || item.shipped.amount === 0)) {
          cssClass = 'text-success'
        }
        rowElement.classList.add(cssClass)
      }
    },
    enableExportColumns() {
      this.gridInstance.columnOption('marketplace_id', 'visible', true)

      this.gridInstance.columnOption('available', 'visible', true)
      this.gridInstance.columnOption('fc_transfer', 'visible', true)
      this.gridInstance.columnOption('fc_processing', 'visible', true)

      this.gridInstance.columnOption('customer_order', 'visible', true)
      this.gridInstance.columnOption('working', 'visible', true)

      this.gridInstance.columnOption('image', 'visible', false)
      this.gridInstance.columnOption('sellable', 'visible', false)
      this.gridInstance.columnOption('inbound', 'visible', false)
      this.gridInstance.columnOption('unsellable', 'visible', false)
    },
    disableExportColumns() {
      this.gridInstance.columnOption('marketplace_id', 'visible', false)

      this.gridInstance.columnOption('available', 'visible', false)
      this.gridInstance.columnOption('fc_transfer', 'visible', false)
      this.gridInstance.columnOption('fc_processing', 'visible', false)

      this.gridInstance.columnOption('customer_order', 'visible', false)
      this.gridInstance.columnOption('working', 'visible', false)

      this.gridInstance.columnOption('image', 'visible', true)
      this.gridInstance.columnOption('sellable', 'visible', true)
      this.gridInstance.columnOption('inbound', 'visible', true)
      this.gridInstance.columnOption('unsellable', 'visible', true)
    },
    onExported(e) {
      this.disableExportColumns()
      e.component.endUpdate()
    },
    onExporting(e) {
      e.component.beginUpdate()
      this.enableExportColumns()

      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Products Cost')

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        const date = new Date()
        if (e.format === 'csv') {
          workbook.csv.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Inventory ${date.toLocaleDateString('en-US')}.csv`)
          })
        } else {
          workbook.xlsx.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Inventory ${date.toLocaleDateString('en-US')}.xlsx`)
          })
        }
      })
      e.cancel = true
    },
    onShowFilterClick(value) {
      this.expandFilters = value
    },
    getCaptionHint(column) {
      return this.captionHints[column.dataField] || undefined
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/common/grid.scss';
</style>
<style lang="scss" scoped>
#inventory-data-grid {
  min-height: calc(100dvh - 120px);
  max-height: calc(100dvh - 120px);
}
</style>
