<template>
  <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-2">
    <template v-if="isCurrentBreakPointXs">
      <div class="tw-flex tw-flex-col tw-gap-3">
        <div class="tw-flex tw-gap-2">
          <DxTextBox
            v-model="search"
            class="tw-flex-1"
            placeholder="Enter ASIN or keyword"
            :on-key-up="onFilterChange"
            value-change-event="keyup"
          />
          <DxButton
            icon="filter"
            type="default"
            width="35"
            height="35"
            :styling-mode="expandFilters ? 'contained' : 'outlined'"
            @click="onSearchButtonClick"
          />
        </div>
        <DxTagBox
          v-show="expandFilters"
          v-model="marketplaces"
          :data-source="marketplaceOptions"
          :search-enabled="true"
          :max-displayed-tags="2"
          :show-multi-tag-only="false"
          display-expr="title"
          value-expr="id"
          placeholder="All Marketplaces"
          item-template="item"
          :on-value-changed="onFilterChange"
        >
          <template #item="{ data }">
            <div class="d-flex flex-row justify-content-between align-items-center">
              <div class="flex-1">
                {{ data.title }}
              </div>
              <div>
                <CountryFlag :country="data.country.toLowerCase()" />
              </div>
            </div>
          </template>
        </DxTagBox>
      </div>
    </template>
    <template v-else>
      <DxTextBox
        v-model="search"
        placeholder="Enter ASIN, SKU or keyword"
        :on-key-up="onFilterChange"
        value-change-event="keyup"
        width="25%"
      />
      <DxTagBox
        v-model="marketplaces"
        :data-source="marketplaceOptions"
        :search-enabled="true"
        :max-displayed-tags="2"
        :show-multi-tag-only="false"
        display-expr="title"
        value-expr="id"
        placeholder="All Marketplaces"
        item-template="item"
        :on-value-changed="onFilterChange"
        width="25%"
      >
        <template #item="{ data }">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="flex-1">
              {{ data.title }}
            </div>
            <div>
              <CountryFlag :country="data.country.toLowerCase()" />
            </div>
          </div>
        </template>
      </DxTagBox>
      <DxSelectBox
        v-model="stock"
        :items="stockOptions"
        display-expr="title"
        value-expr="id"
        :search-enabled="true"
        :show-clear-button="true"
        width="25%"
        @value-changed="onFilterChange"
      />
      <DxSelectBox
        v-model="status"
        :items="statusOptions"
        display-expr="title"
        value-expr="id"
        :search-enabled="true"
        :show-clear-button="true"
        width="25%"
        @value-changed="onFilterChange"
      />
    </template>
  </div>
</template>

<script>
import DxTextBox from 'devextreme-vue/text-box'
import DxTagBox from 'devextreme-vue/tag-box'
import CountryFlag from 'vue-country-flag'
import DxButton from 'devextreme-vue/button'
import { DxSelectBox } from 'devextreme-vue'

export default {
  name: 'Filters',
  components: {
    DxSelectBox,
    DxButton,
    DxTextBox,
    DxTagBox,
    CountryFlag,
  },
  props: {
    expandFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: null,
      marketplaces: [],
      debounce: null,
      stock: null,
      stockOptions: [
        { id: 'out_of_stock', title: 'Out Of Stock' },
        { id: 'too_low', title: 'Too Low' },
        { id: 'low', title: 'Low' },
        { id: 'ideal', title: 'Ideal' },
        { id: 'high', title: 'High' },
      ],
      status: null,
      statusOptions: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' },
        { id: 'incomplete', title: 'Incomplete' },
      ],
    }
  },
  computed: {
    marketplaceOptions() {
      return this.$store.getters['app/availableMarketplaceOptions']
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
  },
  mounted() {
    this.search = this.$route.query.search || null
    this.marketplaces = this.$route.query.marketplaces || []
    this.stock = this.$route.query.stock || null
    this.status = this.$route.query.status || null
  },
  methods: {
    onFilterChange() {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      this.debounce = setTimeout(() => {
        this.updateUrl()
        this.$emit('on-update', JSON.parse(JSON.stringify({
          search: this.search,
          marketplaces: this.marketplaces,
          stock: this.stock,
          status: this.status,
        })))
      }, 500)
    },
    updateUrl() {
      const query = {}
      if (this.search) {
        query.search = this.search
      }
      if (this.marketplaces.length) {
        query.marketplaces = this.marketplaces
      }
      if (this.stock) {
        query.stock = this.stock
      }
      if (this.status) {
        query.status = this.status
      }
      this.$router.replace({
        query,
      })
    },
    onSearchButtonClick() {
      this.$emit('on-show-filter-click', !this.expandFilters)
    },
  },
}
</script>

<style scoped>

</style>
