<template>
  <DataGrid />
</template>

<script>
import DataGrid from '@/views/inventory-v2/components/DataGrid.vue'

export default {
  name: 'Inventory',
  components: { DataGrid },
}
</script>

<style scoped>

</style>
